<template>
   <base-layout page-title="User Profile" page-default-back-link="/">
     <ion-content text-center v-if="currentUser">
         <ion-grid>
             <ion-row>
                 <ion-col offset-md="2" size-md="8" offset-lg="4" size-lg="4" offset-sm="0" size-sm="12">
                    <ion-card class="ion-text-center">
                        <div class="profile-content">
                            
                            <ion-avatar class="ion-text-center profile-avatar">
                                <img :src="currentUser.photoURL" style="display: block; margin-left: auto; margin-right: auto;"/>
                            </ion-avatar>
                                
                           
                            <div style="margin-left: auto; margin-right: auto;">
                                <span v-if="currentUser.admin">
                                    <ion-icon :icon="shieldHalfOutline" style="color: white"/>   
                                </span>
                                <span v-if="currentUser.barista">
                                    <ion-icon :icon="cafeOutline" style="color: white"/>   
                                </span> 
                            </div>

                            <ion-card-header class="box">                           
                                <div v-if="currentUser" class="text"> 
                                    {{currentUser.name}}
                                    <div><br></div>
                                    <div class="text2">
                                        {{currentUser.email}}
                                    </div>
                                </div>
                            </ion-card-header>

                            <ion-button type="button" @click="logout" style="color: primary"> <div class="text">Logout</div></ion-button>
                        </div>
                    </ion-card>     
                 </ion-col>
             </ion-row>
         </ion-grid>
        
        </ion-content> 
    </base-layout>               
</template>

<style>
.text {
    font-size: 18px;
    font-weight: bold;
    color: rgb(224, 220, 220);
}
.text2 {
    font-weight: bold;
    color: rgb(224, 220, 220);
    font-size: 9px;
}
.box {
        border-radius: 3px;
        background-color:  rgba(218, 165, 147, 0.7);
        width: 60%; 
        margin-left: auto; 
        margin-right: auto;
    }


.profile-avatar {
    margin: auto;
    z-index: 1000;
}

.profile-content {
    padding-top: 50px;
    background: url(/assets/profileback.png) no-repeat top fixed, #fff;
    
    position: relative;
    height: 300px;
    width: 100%;
    
}

</style>

<script>
//import {IonButton, IonCard, IonCardSubtitle, IonAvatar, IonGrid, IonRow, IonCol} from '@ionic/vue';
import { shieldHalfOutline, cafeOutline } from 'ionicons/icons';
export default {
    components: {
        // IonButton,
        // IonCard,
        // IonCardSubtitle,
        // IonAvatar,
        // IonGrid,
        // IonRow,
        // IonCol,
    },
     data() {
        return {
            shieldHalfOutline,
            cafeOutline
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.loggedInUser;
        }
    },    
    methods: {
        logout() {
            this.$store.dispatch('signout');

            setTimeout(() => {
                this.$router.push('/');
                }, 1);
        }
    }
}
</script>